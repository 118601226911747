















import { Vue, Prop, Component } from 'vue-property-decorator';
import { Club } from '@/modules/login/models/club.model';

@Component
export default class ClubItem extends Vue {
  @Prop() club!: Club
  
  clubSelected(club: Club): void {
    this.$emit('clubSelected', club);
  }
}
