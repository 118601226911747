var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.hasCustomBackground ? "" : "backend-gray",
        "backend",
        "login",
      ],
      style: [_vm.backgroundImageStyle],
    },
    [
      _c("main", [
        _c("div", { staticClass: "row h-100" }, [
          _c(
            "div",
            {
              staticClass: "col-12 col-md-9 mx-auto login-container",
              staticStyle: { "max-width": "500px", top: "10%" },
            },
            [
              _vm.showLoginForm
                ? _c("LoginForm", { staticClass: "mt-3" })
                : _c("SelectClub", { staticClass: "mt-3" }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }