var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "list-group-item list-group-item-action",
      on: {
        click: function ($event) {
          return _vm.clubSelected(_vm.club)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "card mb-3", staticStyle: { "max-width": "540px" } },
        [
          _c("div", { staticClass: "row no-gutters" }, [
            _c("div", { staticClass: "col-sm-4 justify-content-between" }, [
              _vm.club.logoUrl
                ? _c("img", {
                    staticClass: "card-top-img mx-auto col-sm-12 mb-2",
                    attrs: { src: _vm.club.logoUrl, alt: _vm.club.name },
                  })
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "col-sm-8 d-flex justify-content-between" },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "ml-3 card-title mx-auto mx-sm-0 font-weight-bold align-self-center",
                  },
                  [_vm._v(_vm._s(_vm.club.name))]
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }