



























































import { Vue, Component } from 'vue-property-decorator';
import { LoginType } from '../../models/login-type.model';
import { Getter, Action } from 'vuex-class';
import { Club } from '@/modules/login/models/club.model';
import { LoginCredentials } from '@/modules/login/models/login-credentials.model';
import { LoginTypeEnum } from '../../models/login-type.enum';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators'
import { ToastHelper } from '@/shared/helpers/toast.helper';

@Component
export default class LoginForm extends Vue {
  loginTypes: LoginType[] = [
    { type: LoginTypeEnum.FederationMembershipNumber, text: 'Bondsnummer' },
    { type: LoginTypeEnum.ClubMembershipNumber, text: 'Clublidnummer' }
  ];
  selectedLoginType = this.loginTypes[0];

  @Validate({required})
  membershipNumber = '';
  @Validate({required})
  password = '';

  @Getter
  currentClub!: Club;

  @Action
  clearCurrentClub!: () => Promise<void>
  @Action
  login!: (credentials: LoginCredentials) => Promise<void>

  created(): void {
    this.checkClubSport();
  }

  async tryLogin(): Promise<void> {
    if (this.$v.$invalid) {
      return;
    }
    const federationMembershipNumber = this.selectedLoginType.type === LoginTypeEnum.FederationMembershipNumber ? this.membershipNumber : null;
    const clubMembershipNumber = this.selectedLoginType.type === LoginTypeEnum.ClubMembershipNumber ? this.membershipNumber : null;

    try {
      await this.login(new LoginCredentials(federationMembershipNumber, clubMembershipNumber, this.password))
      await this.$router.push(this.$route.query.redirect ? this.$route.query.redirect.toString() : '/');
    } catch (e) {
      this.password = '';
      ToastHelper.showError(this.$bvToast, `De combinatie van nummer en wachtwoord is niet geldig.`);
    }
  }

  async chooseDifferentClub(): Promise<void> {
    await this.clearCurrentClub();
  }

  checkClubSport(): void {
    if (!this.currentClub.isHockey) {
      return;
    }
    this.loginTypes = [ this.loginTypes[1] ];
    this.selectedLoginType = this.loginTypes[0];
  }
}
