var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-narrow py-4 col-12" }, [
    _c("div", { staticClass: "text-center mb-4 col-12" }, [
      !_vm.currentClub.logoUrl
        ? _c("h1", { staticClass: "h3 mb-3 font-weight-normal" }, [
            _vm._v("Log in op " + _vm._s(_vm.currentClub.name)),
          ])
        : _c("img", {
            staticStyle: { "max-width": "200px" },
            attrs: { src: _vm.currentClub.logoUrl },
          }),
    ]),
    _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        {
          staticClass: "form-group",
          style: { display: _vm.loginTypes.length > 1 ? "block" : "none" },
        },
        [
          _c("label", [_vm._v("Inloggen met")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedLoginType,
                  expression: "selectedLoginType",
                },
              ],
              staticClass: "form-control placeholder-changer",
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedLoginType = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.loginTypes, function (loginType) {
              return _c(
                "option",
                { key: loginType.type, domProps: { value: loginType } },
                [_vm._v(_vm._s(loginType.text))]
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.$v.membershipNumber.$dirty
                ? !_vm.$v.membershipNumber.$error
                : null,
              placeholder: _vm.selectedLoginType.text,
            },
            model: {
              value: _vm.$v.membershipNumber.$model,
              callback: function ($$v) {
                _vm.$set(
                  _vm.$v.membershipNumber,
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.membershipNumber.$model",
            },
          }),
          _vm.$v.membershipNumber.$error
            ? _c("b-form-invalid-feedback", [
                _vm._v(
                  " " + _vm._s(_vm.selectedLoginType.text) + " is verplicht "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.$v.password.$dirty ? !_vm.$v.password.$error : null,
              placeholder: "Wachtwoord",
              type: "password",
            },
            model: {
              value: _vm.$v.password.$model,
              callback: function ($$v) {
                _vm.$set(
                  _vm.$v.password,
                  "$model",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "$v.password.$model",
            },
          }),
          _vm.$v.password.$error
            ? _c("b-form-invalid-feedback", [
                _vm._v(" Wachtwoord is verplicht "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row d-flex justify-content-between" },
        [
          _c(
            "div",
            { staticClass: "col-6 d-flex remember-me align-items-center" },
            [
              _c("b-form-checkbox", [
                _c("div", { attrs: { id: "remember-me-label" } }, [
                  _vm._v("Onthoud mij"),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "router-link",
            {
              staticClass: "reset-password-button pr-3",
              attrs: { to: "/reset-password" },
            },
            [_vm._v(_vm._s(_vm.$t("login.resetPassword")))]
          ),
        ],
        1
      ),
      _c(
        "button",
        {
          ref: "loginButton",
          staticClass: "btn login-button btn-block mt-3",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.tryLogin.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("login.loginButton")) + " ")]
      ),
      _c(
        "a",
        {
          staticClass: "d-block text-center small-text mt-3",
          attrs: { id: "select-club" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.chooseDifferentClub.apply(null, arguments)
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("login.chooseAnotherClub")))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }