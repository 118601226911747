var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-12" }, [
    _c("div", { staticClass: "text-center mb-4 mt-5" }, [
      _vm.logoUrl
        ? _c("img", {
            staticStyle: { "max-width": "200px" },
            attrs: {
              src: _vm.logoUrl,
              alt: _vm.currentFederation ? _vm.currentFederation.name : "",
            },
          })
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("multiselect", {
          attrs: {
            label: "name",
            options: _vm.data,
            id: "club-search",
            placeholder: _vm.$t("login.selectClub"),
            "open-direction": "bottom",
            multiple: false,
            searchable: true,
            loading: _vm.isSearching,
            "internal-search": false,
            "max-height": 600,
            "clear-on-select": true,
            "show-no-results": false,
            "show-no-options": false,
            "show-labels": false,
          },
          on: { "search-change": _vm.onSearchChanged },
          model: {
            value: _vm.selectedClub,
            callback: function ($$v) {
              _vm.selectedClub = $$v
            },
            expression: "selectedClub",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "col-12 small-text mt-2" }, [
      _vm._v(" " + _vm._s(_vm.$t("login.hint")) + " "),
    ]),
    _c("div", { staticClass: "col-12 mt-4" }, [
      _c(
        "button",
        {
          staticClass: "btn login-button mb-4",
          attrs: { disabled: !_vm.selectedClub },
          on: { click: _vm.confirmSelectedClub },
        },
        [_vm._v(" " + _vm._s(_vm.$t("login.choose")) + " ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }