export class LoginCredentials {
  public federationMembershipNumber: string | null;
  public clubMembershipNumber: string | null;
  public password: string;

  constructor(federationMembershipNumber: string | null, clubMembershipNumber: string | null, password: string) {
    this.federationMembershipNumber = federationMembershipNumber;
    this.clubMembershipNumber = clubMembershipNumber;
    this.password = password;
  }

}