













import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import LoginForm from './components/LoginForm.vue';
import SelectClub from './components/SelectClub.vue';
import { Club } from '../models/club.model';
import { IClubApiService } from '../services/club-api.service';
import { inject } from 'inversify-props';
import { ToastHelper } from '@/shared/helpers/toast.helper';

@Component({
  components: {
    LoginForm,
    SelectClub
  }
})
export default class LoginPage extends Vue {
  @Prop()
  clubId: string| undefined;

  @Getter
  private currentClub!: Club | null

  @inject()
  private clubApiService!: IClubApiService; 

  @Action
  private setCurrentClub!: (club: Club) => Promise<void>

  get showLoginForm(): boolean {
    return !!this.currentClub?.id;
  }

  get customBackgroundImage(): string | null {
    if (!this.showLoginForm) {
      return null;
    }

    return this.currentClub?.myEnvLoginBackground ?? null;
  }

  get hasCustomBackground() : boolean {
    return this.customBackgroundImage !== null && this.customBackgroundImage !== '';
  }

  get backgroundImageStyle() : { backgroundImage: string, backgroundSize: string } {
    let imgSrc = '';

    if (!this.showLoginForm) {
      imgSrc = 'url(\'/bg2.jpg\')';
    }
    else if (this.hasCustomBackground) {
      imgSrc = 'url(' + this.customBackgroundImage + ')';
    }
    else {
      imgSrc = 'url(\'/bg2.jpg\')';
    }

    return {
      backgroundImage: imgSrc,
      backgroundSize: 'cover'
    };
  }

  async created(): Promise<void> {
    await this.fetchClub();
  }

  async fetchClub(): Promise<void> {
    if (!this.clubId) {
      return;
    }      

    const club = await this.clubApiService.getClub(this.clubId);
    if (!club) {
      ToastHelper.showError(this.$bvToast, this.$t('login.clubNotFound'));
      return;
    }

    await this.setCurrentClub(club);    
  }
}
