







































import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Federation } from '@/modules/login/models/federation.model';
import { Club } from '@/modules/login/models/club.model';
import { IClubApiService } from '../../services/club-api.service';
import { inject } from 'inversify-props';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import ClubItem from './ClubItem.vue';

@Component({
  components: {
    ClubItem
  }
})
export default class SelectClub extends Vue {
  @inject()
  private clubApiService!: IClubApiService;

  minLength = 3;
  isSearching = false;
  data: Club[] = [];
  selectedClub: Club | null = null;

  @Getter
  currentFederation!: Federation;

  @Action
  setCurrentClub!: (club: Club) => Promise<void>

  async onSearchChanged(search: string): Promise<void> {
    if (search.length < this.minLength || this.isSearching) {
      return;
    }

    try {
      this.isSearching = true;
      this.data = await this.clubApiService.findClubs(this.currentFederation.id, search);
    } catch {
      ToastHelper.showError(this.$bvToast, `An unexpected error has occured.`)
    } finally {
      this.isSearching = false;
    }
  }

  async confirmSelectedClub(): Promise<void> {
    await this.setCurrentClub(this.selectedClub!);
  }

  get logoUrl(): string {
    return this.currentFederation?.logoUrl;
  }
}
